import { useQuery } from "@tanstack/react-query";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SkippAuthentication } from "../../util/Envs";

export const msTokenKey = "msToken"
export const msNameKey = "msName"

export const scopes = ["api://e3189dbc-8edf-497a-b863-99e9b355d9a7/Usage"]

export const useUserToken = () => {
    const { msToken, allReady: isAuthenticated } = useAuthStateQuery()

    return { msToken, isAuthenticated }
}

export const useAuthStateQuery = () => {
    const { instance, accounts } = useMsal()
    const msalIsAuthenticated = useIsAuthenticated()

    const { data: msName } = useQuery({
        queryKey: [msNameKey],
        queryFn: () => {
            return accounts[0].name
        },
        staleTime: 0,
        enabled: msalIsAuthenticated
    })

    const { data: msToken } = useQuery({
        queryKey: [msTokenKey],
        queryFn: async () => {
            const account = accounts[0]
            const response = await instance.acquireTokenSilent({
                scopes: scopes,
                account: account,
            })
            return response.accessToken
        },
        staleTime: 0,
        enabled: msalIsAuthenticated
    })

    const authState = {
        msToken: msToken,
        msName: msName,
        allReady: msToken !== undefined && (msalIsAuthenticated || SkippAuthentication)
    }

    return authState
}