import { FC, useState } from "react"
import { Modal, Button, Form } from "react-bootstrap"
import { useEditRepeatingCommodity } from "../store/repeatingCommodities/RepeatingCommodityMutations"

type EditRepeatingCommodityModalProps = {
    show?: boolean,
    commodityId: string
    onClose: () => void
}
export const EditRepeatingCommodityModal: FC<EditRepeatingCommodityModalProps> = ({ show, commodityId, onClose }) => {

    const editRepeatingCommodity = useEditRepeatingCommodity()
    const [endDate, setEndDate] = useState('')

    const onEdit = async () => {
        await editRepeatingCommodity.mutateAsync({ repeatingCommodityId: commodityId, endDate: endDate })

        onClose()
    }

    return (
        <Modal show={show ?? false} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>End-Datum ändern</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Control required type="date" name="date" placeholder="42" onChange={(e) => setEndDate(e.target.value)} value={endDate} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Schließen
                </Button>
                <Button variant="primary" onClick={onEdit}>
                    Ändern
                </Button>
            </Modal.Footer>
        </Modal>
    )
}