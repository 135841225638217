import { FC } from "react"
import { TimeSpan } from "./TimeSpanSelection"
import { useBillableCommoditiesQuery } from "../store/billables/BillableQueries"
import { Button, Form, Spinner, Table } from "react-bootstrap"
import { localStringFrom } from "../util/NumberUtil"
import { useCreateInvoiceMutation } from "../store/invoices/InvoiceMutations"
import { BuildInvoiceActionResource } from "../generated/BackendApiClient"
import { useNavigate } from "react-router-dom"
import { asLocalDate } from "../util/DateUtil"

type BillableItemSelectionProps = {
    timeSpan: TimeSpan,
    customerId: string
}

export const BillableItemSelection: FC<BillableItemSelectionProps> = ({ timeSpan, customerId }) => {

    const {
        billableOneTimeCommodities,
        billableRepeatingCommodities,
        isError,
        isLoading
    } = useBillableCommoditiesQuery(customerId, timeSpan.startDate, timeSpan.endDate)
    const createInvoiceMutation = useCreateInvoiceMutation()
    const navigate = useNavigate()

    if (isLoading) return <Spinner />

    const onSubmit = async (e: any) => {
        const oneTimeSelections = billableOneTimeCommodities
            ?.map(c => ({id:c.id, checked:(e[c.id] as any).checked}))
            .filter(c => c.checked).map(c => c.id) ?? []
        const repeatingSelections = billableRepeatingCommodities
            ?.map(c => ({id:c.id, checked:(e[c.id] as any).checked}))
            .filter(c => c.checked).map(c => c.id) ?? []
        const comment = e['comment'].value
        const dueDate = e['dueDate'].value
        const invoiceDate = e['invoiceDate'].value

        const data: BuildInvoiceActionResource = {
            comment,
            invoiceDate,
            dueDate,
            customerId,
            startDate: timeSpan.startDate,
            endDate: timeSpan.endDate,
            oneTimeCommodityIds: oneTimeSelections,
            repeatingCommodityIds: repeatingSelections
        }

        //TODO validate
        
        const newInvoice = await createInvoiceMutation.mutateAsync(data)
        navigate(`/invoices/${newInvoice.id}`)
    }

    return (
        <>
            <h3>{timeSpan.startDate} - {timeSpan.endDate}</h3>
            <Form onSubmit={(e) => { e.preventDefault(); onSubmit(e.target) }}>
                <h4 className="pt-2">Einmalige Dienstleistungen</h4>
                <Table striped>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Kunde</th>
                            <th className="text-right">#</th>
                            <th></th>
                            <th>Einzelpreis</th>
                            <th>Datum</th>
                            <th>Beschreibung</th>
                        </tr>
                    </thead>
                    <tbody>
                        {billableOneTimeCommodities?.map(c =>
                            <tr key={'oneTime'+c.id}>
                                <td>
                                    <Form.Check
                                        type='checkbox'
                                        id={c.id}
                                        name={c.id}
                                        defaultChecked={true}
                                    />
                                </td>
                                <td>{c.name}</td>
                                <td>{c.receivingCustomer.name}</td>
                                <td className="text-right">{c.amount}</td>
                                <td>{c.unit}</td>
                                <td>{localStringFrom(c.costOfOne)}€</td>
                                <td>{asLocalDate(c.date)}</td>
                                <td>{c.description}</td>
                            </tr>
                        )}
                        {(billableOneTimeCommodities?.length ?? 0) === 0 && !isError && !isLoading &&
                            <tr>
                                <td colSpan={9}>Es gibt keine Dienstleistungen in diesem Zeitraum...</td>
                            </tr>
                        }
                        {isError &&
                            <tr>
                                <td colSpan={9}>Es gab einen fehler beim Laden der Dienstleistungen...</td>
                            </tr>
                        }
                        {isLoading &&
                            <tr>
                                <td colSpan={9}><Spinner /></td>
                            </tr>
                        }
                    </tbody>
                </Table>
                <h4 className="pt-5">Wiederkehrende Dienstleistungen</h4>
                <Table striped>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Kunde</th>
                            <th className="text-right">#</th>
                            <th></th>
                            <th>Einzelpreis</th>
                            <th>Bezahlt bis</th>
                            <th>Beschreibung</th>
                        </tr>
                    </thead>
                    <tbody>
                        {billableRepeatingCommodities?.map(c =>
                            <tr key={'repeat'+c.id}>
                                <td>
                                    <Form.Check
                                        type='checkbox'
                                        id={c.id}
                                        name={c.id}
                                        defaultChecked={true}
                                    />
                                </td>
                                <td>{c.name}</td>
                                <td>{c.receivingCustomer.name}</td>
                                <td className="text-right">{c.amountPerMonth}</td>
                                <td>{c.unit}</td>
                                <td>{localStringFrom(c.costPerAmount)}€</td>
                                <td>{asLocalDate(c.lastBilled) ?? '-'}</td>
                                <td>{c.description}</td>
                            </tr>
                        )}
                        {(billableOneTimeCommodities?.length ?? 0) === 0 && !isError && !isLoading &&
                            <tr>
                                <td colSpan={9}>Es gibt keine Dienstleistungen in diesem Zeitraum...</td>
                            </tr>
                        }
                        {isError &&
                            <tr>
                                <td colSpan={9}>Es gab einen fehler beim Laden der Dienstleistungen...</td>
                            </tr>
                        }
                        {isLoading &&
                            <tr>
                                <td colSpan={9}><Spinner /></td>
                            </tr>
                        }
                    </tbody>
                </Table>
                <Form.Group className="mb-3">
                    <Form.Label>Kommentart (optional)</Form.Label><br/>
                    <textarea className="w-100 border-solid border-2" name="comment"></textarea>
                </Form.Group>
                <div className="flex flex-row gap-5">
                    <Form.Group className="mb-3 w-40">
                        <Form.Label>Rechnungsdatum</Form.Label>
                        <Form.Control required type="date" name="invoiceDate" />
                    </Form.Group>
                    <Form.Group className="mb-3 w-40">
                        <Form.Label>Fälligkeitsdatum</Form.Label>
                        <Form.Control required type="date" name="dueDate" />
                    </Form.Group>
                </div>
                <Button className="w-96" variant="primary" type="submit">
                    Rechnung erstellen
                </Button>
            </Form>
        </>
    )
}