import { QueryClient } from '@tanstack/react-query'
import { SkippAuthentication, UseMock } from './util/Envs'
import { Minute } from './util/Time'
import { PublicClientApplication } from "@azure/msal-browser"
import { msTokenKey, msNameKey } from './store/token/TokenQuery'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { persistQueryClient } from '@tanstack/react-query-persist-client'

export type SetupConfiguration = {
    queryClient: QueryClient,
    msalInstance: PublicClientApplication
}

export const setUp = async (): Promise<SetupConfiguration> => {
    if (UseMock) {
        await enableMocking()
    }

    if (SkippAuthentication) {
        skippAuth(queryClient)
    }

    return { queryClient: queryClient, msalInstance: msalInstance }
}

const skippAuth = (queryClient: QueryClient) => {
    queryClient.setQueryData([msTokenKey], 'msDummyToken')
    queryClient.setQueryData([msNameKey], 'Max Mustermann')
}

const msalConfig = {
    clientId: 'e3189dbc-8edf-497a-b863-99e9b355d9a7',
    authority: 'https://login.microsoftonline.com/bfa98759-cf17-4f76-a45b-7f0474af636f',
    redirectUri: '/'
}

const enableMocking = async () => {
    const { apiMockWorker: server } = await import('./services/BackendClientMock')
    return server.start()
}


const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 3 * Minute,
            retry: 2,
            retryDelay: attempt => Math.min(200 * 2 ** attempt, 5000)
        }
    }
})

const sessionStoragePersister = createSyncStoragePersister({ storage: window.sessionStorage })

persistQueryClient({
    queryClient: queryClient,
    persister: sessionStoragePersister,
    maxAge: 5 * Minute
})

const msalInstance = new PublicClientApplication({ auth: msalConfig })