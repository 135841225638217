import './App.css';
import { useAuthStateQuery } from './store/token/TokenQuery';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { CustomersPage } from './pages/CustomerPage';
import { LoginPage } from './pages/LoginPage';
import { CommoditiesPage } from './pages/CommoditiesPage';
import { InvoicePage } from './pages/InvoicePage';
import { NavBar } from './components/NavBar';
import { InvoicePreview } from './pages/InvoicePreviewPage';
import { FC, useEffect } from 'react';
import { BillingPage } from './pages/BillingPage';
import { HomePage } from './pages/HomePage';
import { prefetchOneTimeCommodities } from './store/oneTimeCommodities/OneTimeCommodityQueries';
import { useQueryClient } from '@tanstack/react-query';
import { prefetchRepeatingCommodities } from './store/repeatingCommodities/RepeatingCommodityQueries';
import { prefetchInvoices } from './store/invoices/InvoiceQueries';
import { prefetchCustomers } from './store/customers/CustomerQueries';

export const App : FC = () => {

  const { allReady, msToken } = useAuthStateQuery()
  const queryClient = useQueryClient()

  useEffect(() => {
    if(!allReady || !msToken) return;

    prefetchRepeatingCommodities(msToken, queryClient)
    prefetchOneTimeCommodities(msToken, queryClient)
    prefetchInvoices(msToken, queryClient)
    prefetchCustomers(msToken, queryClient)
  }, [allReady])

  if (!allReady) return <div className='flex flex-column items-center md:px-10 mx-3 mt-10 gap-4 md:gap-5 mb-20'><LoginPage /></div>

  return (
    <>
      <NavBar />
      <div className='flex flex-column items-center md:px-10 mx-3 mt-10 gap-4 md:gap-5 mb-20'>
        <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route index Component={HomePage} />
              <Route path="customers" Component={CustomersPage} />
              <Route path="commodities" Component={CommoditiesPage} />
              <Route path="invoices" Component={InvoicePage} />
              <Route path="invoices/:invoiceId" Component={InvoicePreview} />
              <Route path="bill/:customerId" Component={BillingPage} />
              <Route path="login" Component={LoginPage} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </>
  )
}