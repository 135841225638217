import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useState } from "react"
import { CreateRepeatingCommodityResource } from "../generated/BackendApiClient"
import { useCustomerQuery } from "../store/customers/CustomerQueries"
import { useCreateRepeatingCommodity } from "../store/repeatingCommodities/RepeatingCommodityMutations"
import { Form } from "react-bootstrap"

const emptyCreationData: CreateRepeatingCommodityResource = {
    name: '',
    amountPerMonth: 0,
    costPerAmount: 0,
    startDate: '',
    endDate: undefined,
    description: '',
    receivingCustomerId: '',
    unit: 'Stk/Monat'
}

export const RepeatingCommoditiesCreationLine: FC = () => {
    const [data, setData] = useState<CreateRepeatingCommodityResource>(emptyCreationData)
    const [hasEndDate, setHasEndDate] = useState(false)
    const { customers } = useCustomerQuery()
    const createRepeatingCommodity = useCreateRepeatingCommodity()

    const onChange: React.ChangeEventHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name
        const value = event.target.value
        setData(oldData => ({ ...oldData, [name]: value }))
    }

    const onCreateClicked = async () => {
        const cleaned = {
            ...data,
            receivingCustomerId: data.receivingCustomerId === '' ? customers![0].id : data.receivingCustomerId,
            endDate: hasEndDate ? data.endDate : undefined
        }

        await createRepeatingCommodity.mutateAsync(cleaned)
        setData(emptyCreationData)
    }

    return (
        <tr key='create'>
            <td></td>
            <td><Form.Control required type="text" name="name" placeholder="Erstellen einer Webseite" onChange={onChange} value={data.name} /></td>
            <td>
                <Form.Select aria-label="Default select example" name="receivingCustomerId" onChange={onChange} value={data.receivingCustomerId} >
                    {customers?.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
                </Form.Select>
            </td>
            <td><Form.Control required type="number" name="amountPerMonth" placeholder="42" onChange={onChange} value={data.amountPerMonth} /></td>
            <td>
                <Form.Select aria-label="Default select example" name="unit" onChange={onChange} value={data.unit} >
                    <option value='h'>h/Monat</option>
                    <option value='Stk'>Stk/Monat</option>
                </Form.Select>
            </td>
            <td><Form.Control required type="number" name="costPerAmount" placeholder="42" onChange={onChange} value={data.costPerAmount} /></td>
            <td>
                <Form.Control required type="date" name="startDate" onChange={onChange} value={data.startDate} />
                <Form.Check
                    type="switch"
                    label="Hat ein End-Datum"
                    checked={hasEndDate}
                    onChange={e => setHasEndDate(e.target.checked)}
                />
                {hasEndDate && <Form.Control required type="date" name="endDate" onChange={onChange} value={data.endDate ?? ''} />}
            </td>
            <td></td>
            <td><Form.Control required type="text" name="description" placeholder="Erstellen der Webseite domain.de" onChange={onChange} value={data.description} /></td>
            <td><FontAwesomeIcon className="cursor-pointer px-3" icon={faPlus} onClick={onCreateClicked} /></td>
        </tr>
    )
}