import { useQueryClient, useMutation } from "@tanstack/react-query"
import { CreateRepeatingCommodityResource, EditRepeatingCommodityResource, RepeatingCommodityResource } from "../../generated/BackendApiClient"
import { GetBackendClient } from "../../services/BackendClientFactory"
import { useAuthStateQuery } from "../token/TokenQuery"
import { repeatingCommodityQueryKey } from "./RepeatingCommodityQueries"

export const useCreateRepeatingCommodity = () => {
    const { msToken } = useAuthStateQuery()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (args: CreateRepeatingCommodityResource) => {
            const resp = await GetBackendClient(msToken).repeatingCommodities.createRepeatingCommodity(args)
            return resp.data
        },
        onSuccess: (newCommodity: RepeatingCommodityResource) => {
            queryClient.setQueryData([repeatingCommodityQueryKey], (oldCommodities: RepeatingCommodityResource[] | undefined) => [...oldCommodities ?? [], newCommodity])
        }
    })
}

export const useDeleteRepeatingCommodity = () => {
    const { msToken } = useAuthStateQuery()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (toBeDeletedId: string) => {
            const resp = await GetBackendClient(msToken).repeatingCommodities.deleteRepeatingCommodity(toBeDeletedId)
            return resp.data
        },
        onSuccess: (deletedCommodity: RepeatingCommodityResource) => {
            queryClient.setQueryData([repeatingCommodityQueryKey], (oldCommodities: RepeatingCommodityResource[] | undefined) => oldCommodities?.filter(c => c.id !== deletedCommodity.id))
        }
    })
}

export const useEditRepeatingCommodity = () => {
    const { msToken } = useAuthStateQuery()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (args: EditRepeatingCommodityResource) => {
            const resp = await GetBackendClient(msToken).repeatingCommodities.editRepeatingCommodity(args)
            return resp.data
        },
        onSuccess: (updatedCommodity: RepeatingCommodityResource) => {
            queryClient.setQueryData([repeatingCommodityQueryKey], (oldCommodities: RepeatingCommodityResource[] | undefined) => [...oldCommodities?.filter(c => c.id !== updatedCommodity.id) ?? [], updatedCommodity])
        }
    })
}