import { FC, useState } from "react"
import { useRepeatingCommodityQuery } from "../store/repeatingCommodities/RepeatingCommodityQueries"
import { useDeleteRepeatingCommodity } from "../store/repeatingCommodities/RepeatingCommodityMutations"
import { RepeatingCommodityResource } from "../generated/BackendApiClient"
import { Accordion, Spinner, Table } from "react-bootstrap"
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { RepeatingCommoditiesCreationLine } from "./RepeatingCommoditiesCreationLine"
import { EditRepeatingCommodityModal } from "./EditRepeatingCommodityModal"
import { localStringFrom } from "../util/NumberUtil"
import { asLocalDate } from "../util/DateUtil"

export const RepeatingCommoditiesList: FC = () => {

    const { repeatingCommodities, isError, isLoading } = useRepeatingCommodityQuery()
    const deleteRepeatingCommodity = useDeleteRepeatingCommodity()
    const [modalCommodity, setModalCommodity] = useState<string | undefined>(undefined)

    const onDeleteClicked = (commodity: RepeatingCommodityResource) => async () => {
        if (!window.confirm(`Willst du wirklich '${commodity.name}' löschen?`)) return

        await deleteRepeatingCommodity.mutateAsync(commodity.id)
    }

    const onEditClicked = (commodity: RepeatingCommodityResource) => async () => {
        setModalCommodity(commodity.id)
    }

    return (
        <>
            <div className="md:hidden block w-full">
                {isLoading && <Spinner />}
                <Accordion className="w-full">
                    {repeatingCommodities?.map(c =>
                        <Accordion.Item key={c.id} eventKey={c.id}>
                            <Accordion.Header>{c.name} - {c.receivingCustomer.name}</Accordion.Header>
                            <Accordion.Body>
                                <Table>
                                    <tbody>
                                        <tr><td>Kunde:</td><td>{c.receivingCustomer.name}</td></tr>
                                        <tr><td>#/M:</td><td>{c.amountPerMonth}{c.unit}/M</td></tr>
                                        <tr><td>Einzelpreis:</td><td>{localStringFrom(c.costPerAmount)}€</td></tr>
                                        <tr><td>Datum:</td><td>{asLocalDate(c.startDate)} - {asLocalDate(c.endDate) ?? 'Heute'}</td></tr>
                                        <tr><td>Rechnung bis:</td><td>{asLocalDate(c.lastBilled) ?? '-'}</td></tr>
                                        <tr><td>Beschreibung:</td><td>{c.description}</td></tr>
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                    )}
                </Accordion>
            </div>
            <EditRepeatingCommodityModal show={!!modalCommodity} onClose={() => setModalCommodity(undefined)} commodityId={modalCommodity ?? ''} />
            <div className="hidden md:flex md:flex-col md:items-center w-full">
                <Table striped>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Kunde</th>
                            <th className="text-right">#/M</th>
                            <th></th>
                            <th>Einzelpreis</th>
                            <th>Zeitraum</th>
                            <th>Rechnung bis</th>
                            <th>Beschreibung</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {repeatingCommodities?.map(c =>
                            <tr key={c.id}>
                                <td></td>
                                <td>{c.name}</td>
                                <td>{c.receivingCustomer.name}</td>
                                <td className="text-right">{c.amountPerMonth}</td>
                                <td>{c.unit}</td>
                                <td>{localStringFrom(c.costPerAmount)}€</td>
                                <td>{asLocalDate(c.startDate)} - {asLocalDate(c.endDate) ?? 'Heute'}</td>
                                <td>{asLocalDate(c.lastBilled) ?? '-'}</td>
                                <td>{c.description}</td>
                                <td>
                                    <FontAwesomeIcon className="cursor-pointer px-3" icon={faPen} onClick={onEditClicked(c)} />
                                    <FontAwesomeIcon className="cursor-pointer px-3 text-red-400" icon={faTrash} onClick={onDeleteClicked(c)} />
                                </td>
                            </tr>
                        )}
                        <RepeatingCommoditiesCreationLine />
                        {(repeatingCommodities?.length ?? 0) === 0 && !isError && !isLoading &&
                            <tr>
                                <td colSpan={9}>Noch keine Dienstleistungen angelegt...</td>
                            </tr>
                        }
                        {isError &&
                            <tr>
                                <td colSpan={9}>Es gab einen fehler beim Laden der Dienstleistungen...</td>
                            </tr>
                        }
                        {isLoading &&
                            <tr>
                                <td colSpan={9}><Spinner /></td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </div>
        </>
    )
}