import { FC } from 'react';

export const InvoiceFooter: FC = () => {

    return (
        <div className='invoice-footer'>
            <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                <div className='spacer-line'></div>
                <div className='misc-infos'>
                    <b>Adresse</b> Im Hänferstück 42 • 77855 • Achern
                    <b>Steuernummer</b> 14098/10540
                    <br />
                    <b>E-Mail</b> contact@blackforestsentinel.de
                    <b>Mobil</b> +4917634307264
                    <b>Website</b> blackforestsentinel.de
                    <br />
                    <b>Bank</b> BNP Paribas Niederlassung Deutschland
                    <b>BIC</b> CSDBDE71XXX
                    <b>&nbsp;IBAN</b> DE56760300800250708107
                    <br />
                    <b>Kontoinhaber</b> Ian Winter &amp; Marcel Krübel Gbr
                </div>
            </div>
        </div>
    )
}