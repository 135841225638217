import { Navbar, Container, Nav } from "react-bootstrap"
import { useAuthStateQuery } from "../store/token/TokenQuery"

export const NavBar = () => {

    const { msName } = useAuthStateQuery()

    return (
        <Navbar expand="lg" className="bg-bfs-green">
            <Container>
                <Navbar.Brand href="/">
                    <img
                        src="/logo512.png"
                        width="40"
                        height="40"
                        className="d-inline-block align-top"
                        alt="Black Forest Sentinel Logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="/customers">Kunden</Nav.Link>
                        <Nav.Link href="/commodities">Dienstleistungen</Nav.Link>
                        <Nav.Link href="/invoices">Rechnungen</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text>
                        Angemeldet als: <a href="/login">{msName}</a>
                    </Navbar.Text>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}