import { useMutation, useQueryClient } from "@tanstack/react-query"
import { msNameKey, msTokenKey } from "./TokenQuery"
import { useMsal } from "@azure/msal-react"


export const useLogoutMutator = () => {
    const queryClient = useQueryClient()
    const { instance } = useMsal()

    const logoutMutation = useMutation({
        mutationFn: async () => {
            await instance.clearCache()
            await queryClient.invalidateQueries({queryKey: [msTokenKey]})
            await queryClient.invalidateQueries({queryKey: [msNameKey]})
            sessionStorage.clear()
        },
    })

    return { logoutMutation }
}