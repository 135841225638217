import { QueryClient, useQuery } from "@tanstack/react-query"
import { GetBackendClient } from "../../services/BackendClientFactory"
import { useAuthStateQuery } from "../token/TokenQuery"

export const customerQueryKey = "customers"

export const useCustomerQuery = () => {
    const { msToken, allReady } = useAuthStateQuery()

    const { data, isError, isLoading } = useQuery({
        queryKey: [customerQueryKey],
        queryFn: getCustomers(msToken),
        enabled: allReady
    })

    const customersById = data !== undefined ? new Map(data.map(i => [i.id, i])) : undefined

    return { customers: data, customersById, isError, isLoading }
}

export const prefetchCustomers = async (msToken: string, queryClient: QueryClient) => {
    await queryClient.prefetchQuery({
        queryKey: [customerQueryKey],
        queryFn: getCustomers(msToken),
    })
}

const getCustomers = (msToken: string | undefined) => async () => {
    const resp = await GetBackendClient(msToken).customers.getCustomers()
    return resp.data
}