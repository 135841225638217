import React, { FC } from 'react';
import { InvoiceResource } from '../../generated/BackendApiClient';

type FullInvoiceProps = {
    invoice: InvoiceResource
}

export const InvoiceLegal: FC<FullInvoiceProps> = ({ invoice }) => {

    return (
        <div className='invoice-legal'>
            <h2>Rechnung</h2>
            <br />
            Zahlbar nach Erhalt der Rechnung<br />
            <br />
            Gemäß § 19 Abs. 1 UStG wird keine Umsatzsteuer berechnet.<br />
            <br />
            Wir bedanken uns für Ihren Auftrag und freuen uns auf die weitere Zusammenarbeit<br />
            <br />
            {invoice.comment && <><pre className='comment'>{invoice.comment}</pre><br /><br /></>}
        </div>
    )
}