import { FC, useState } from "react";
import { Button, Form } from "react-bootstrap";

type TimeSpanSelectionProps = {
    onSubmit: (ts:TimeSpan) => void
}

export type TimeSpan = { startDate: string, endDate: string }

export const TimeSpanSelection : FC<TimeSpanSelectionProps> = ({onSubmit}) => {
    const [data, setData] = useState<TimeSpan>({ startDate: '', endDate: '' })

    const onChange: React.ChangeEventHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name
        const value = event.target.value
        setData(oldData => ({ ...oldData, [name]: value }))
    }

    return (
        <Form style={{ maxWidth: 500 }} onSubmit={(e) => { e.preventDefault(); onSubmit(data) }}>
            <Form.Group className="mb-3">
                <Form.Label>Start Datum</Form.Label>
                <Form.Control required type="month" name="startDate" onChange={onChange} value={data.startDate} />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>End Datum</Form.Label>
                <Form.Control required type="month" name="endDate" onChange={onChange} value={data.endDate} />
            </Form.Group>
            <Button variant="primary" type="submit">
                Zeitraum wählen
            </Button>
        </Form>
    )
}