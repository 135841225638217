import { FC } from 'react';
import { InvoiceResource } from '../../generated/BackendApiClient';
import { localStringFrom } from '../../util/NumberUtil';

type FullInvoiceProps = {
    invoice: InvoiceResource
}

export const InvoiceItems : FC<FullInvoiceProps> = ({invoice}) => {

    return (
    <div className='invoice-items'>
        <table className="table" aria-labelledby="tableLabel">
            <thead>
                <tr>
                    <th>Beschreibung</th>
                    <th className='align-right'>Datum</th>
                    <th className='align-right' colSpan={2}>Anzahl</th>
                    <th className='align-right'>Einzeln</th>
                    <th className='align-right'>Betrag</th>
                </tr>
            </thead>
            <tbody>
                {
                    invoice.items.map(({ id, description, costOfOne, amount, costTotal, unit, dateInfo}, i) => (
                        <tr className={i % 2 === 0 ? 'grey-row' : ''} key={id}>
                            <td>{description}</td>
                            <td className='align-right'>{dateInfo}</td>
                            <td style={{paddingRight: '2px'}} className='align-right'>{localStringFrom(amount)}</td>
                            <td style={{paddingLeft: '0', width:10}}>{unit}</td>
                            <td className='align-right'>{localStringFrom(costOfOne)}€</td>
                            <td className='align-right'>{localStringFrom(costTotal)}€</td>
                        </tr>
                    ))}
            </tbody>
        </table>
        <p style={{float: 'right', fontSize: 'medium'}}><b>Gesamtsumme {localStringFrom(invoice.totalCost)}€</b></p>
    </div>)
}