import { useMutation, useQueryClient } from "@tanstack/react-query"
import { GetBackendClient } from "../../services/BackendClientFactory"
import { useAuthStateQuery } from "../token/TokenQuery"
import { CreateCustomerResource, CustomerResource } from "../../generated/BackendApiClient"
import { customerQueryKey } from "./CustomerQueries"

export const useCreateCustomerMutation = () => {
    const queryClient = useQueryClient()

    const { msToken } = useAuthStateQuery()

    return useMutation({
        mutationFn: async (args: CreateCustomerResource) => {
            const creationArgs = {...args}
            if(creationArgs.contactName === "") creationArgs.contactName = undefined
            const resp = await GetBackendClient(msToken).customers.createCustomer(creationArgs)
            return resp.data
        },
        onSuccess: (newCustomer: CustomerResource) => {
            queryClient.setQueryData([customerQueryKey], (oldCustomers: CustomerResource[] | undefined) => [...oldCustomers ?? [], newCustomer])
        }
    })
}

export const useDeleteCustomerMutation = () => {
    const queryClient = useQueryClient()

    const { msToken } = useAuthStateQuery()

    return useMutation({
        mutationFn: async (toBeDeletedId: string) => {
            const resp = await GetBackendClient(msToken).customers.deleteCustomer(toBeDeletedId)
            return resp.data
        },
        onSuccess: (deletedCustomer: CustomerResource) => {
            queryClient.setQueryData([customerQueryKey], (oldCustomers: CustomerResource[] | undefined) => oldCustomers?.filter(c => c.id !== deletedCustomer.id))
        }
    })
}