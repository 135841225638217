import { Api } from "../generated/BackendApiClient"
import { API_BASEURL } from "../util/Envs"

export const GetBackendClient = (token: string | undefined) : Api<unknown> => {
    return new Api({
        baseUrl: API_BASEURL,
        baseApiParams: {
            credentials: "include",
            redirect: "error",
            referrerPolicy: "strict-origin",
            headers: token ? { Authorization: `Bearer ${token}` } : {}
        }
    })
}