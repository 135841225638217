import { FC } from "react"
import { CustomerForm } from "../components/CustomerForm"
import { CustomerList } from "../components/CustomerList"
import { useCreateCustomerMutation } from "../store/customers/CustomerMutations"

export const CustomersPage : FC = () => {
    const createCustomer = useCreateCustomerMutation()
    
    return (
        <>
            <CustomerList/>
            <h3>Kunden anlegen</h3>
            <CustomerForm onSubmit={customer => createCustomer.mutateAsync(customer)} type='create'/>
        </>
    )
}