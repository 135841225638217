import { FC } from "react";
import { Statistics } from "../components/Statistics";

export const HomePage : FC = () => {

    return (
        <>
            <Statistics/>
        </>
    )
}