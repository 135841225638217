import { QueryClient, useQuery } from "@tanstack/react-query"
import { GetBackendClient } from "../../services/BackendClientFactory"
import { useAuthStateQuery } from "../token/TokenQuery"

export const repeatingCommodityQueryKey = "repeatingCommodities"

export const useRepeatingCommodityQuery = () => {
    const { msToken, allReady } = useAuthStateQuery()

    const { data, isError, isLoading } = useQuery({
        queryKey: [repeatingCommodityQueryKey],
        queryFn: getRepeatingCommodities(msToken),
        enabled: allReady
    })

    return { repeatingCommodities: data, isError, isLoading }
}

export const prefetchRepeatingCommodities = async (msToken: string, queryClient: QueryClient) => {
    await queryClient.prefetchQuery({
        queryKey: [repeatingCommodityQueryKey],
        queryFn: getRepeatingCommodities(msToken),
    })
}

const getRepeatingCommodities = (msToken: string | undefined) => async () => {
    const resp = await GetBackendClient(msToken).repeatingCommodities.getRepeatingCommodities()
    return resp.data
}