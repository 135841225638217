import { faCircleCheck, faEnvelopeOpen, faGlobe, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { Table, Spinner, Accordion, Button } from "react-bootstrap";
import { useInvoiceQuery } from "../store/invoices/InvoiceQueries";
import { localStringFrom } from "../util/NumberUtil";
import { useNavigate } from "react-router-dom";
import { asLocalDate } from "../util/DateUtil";

type InvoiceListProps = {
    showSettled: boolean
}

export const InvoiceList: FC<InvoiceListProps> = ({ showSettled }) => {

    const { invoices, isLoading, isError } = useInvoiceQuery()

    const navigation = useNavigate()
    const settled = invoices?.filter(innvoice => innvoice.isSettled)
    const notSettled = invoices?.filter(innvoice => !innvoice.isSettled)

    const publishedIcon = (isPublished: boolean) => isPublished ? 
        <FontAwesomeIcon icon={faGlobe} /> : <FontAwesomeIcon icon={faLock} />

    return (
        <>
            <div className="md:hidden block w-full">
                {isLoading && <Spinner />}
                <Accordion className="w-full">
                    {notSettled?.map(c =>
                        <Accordion.Item key={c.id} eventKey={c.id}>
                            <Accordion.Header>{c.invoiceNumber} {c.customer.name}</Accordion.Header>
                            <Accordion.Body>
                                <Table>
                                    <tbody>
                                        <tr><td>Kunde:</td><td>{c.customer.name}</td></tr>
                                        <tr><td>Rechnungsdatum:</td><td>{asLocalDate(c.invoiceDate)}</td></tr>
                                        <tr><td>Abrechnungszeitraum:</td><td>{asLocalDate(c.startDate)}-{asLocalDate(c.endDate)}</td></tr>
                                        <tr><td>Fälligkeitsdatum:</td><td>{asLocalDate(c.dueDate) ?? '-'}</td></tr>
                                        <tr><td>Gesamtkosten:</td><td>{localStringFrom(c.totalCost)}€</td></tr>
                                        <tr><td>Öffentlich:</td><td>{c.isPublished ? 'Ja' : 'Nein'} {publishedIcon(c.isPublished)}</td></tr>
                                        <tr><td colSpan={2}><Button className="w-full" onClick={() => navigation(`/invoices/${c.id}`)}>Anzeigen</Button></td></tr>
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>

                    )}
                    {showSettled && settled?.map(c =>
                        <Accordion.Item key={c.id} eventKey={c.id}>
                            <Accordion.Header><FontAwesomeIcon icon={faCircleCheck} className="mr-2" />{c.invoiceNumber} {c.customer.name}</Accordion.Header>
                            <Accordion.Body>
                                <Table>
                                    <tbody>
                                        <tr><td>Kunde:</td><td>{c.customer.name}</td></tr>
                                        <tr><td>Rechnungsdatum:</td><td>{asLocalDate(c.invoiceDate)}</td></tr>
                                        <tr><td>Abrechnungszeitraum:</td><td>{asLocalDate(c.startDate)}-{asLocalDate(c.endDate)}</td></tr>
                                        <tr><td>Fälligkeitsdatum:</td><td>{asLocalDate(c.dueDate) ?? '-'}</td></tr>
                                        <tr><td>Gesamtkosten:</td><td>{localStringFrom(c.totalCost)}€</td></tr>
                                        <tr><td>Öffentlich:</td><td>{c.isPublished ? 'Ja' : 'Nein'} {publishedIcon(c.isPublished)}</td></tr>
                                        <tr><td colSpan={2}><Button className="w-full" onClick={() => navigation(`/invoices/${c.id}`)}>Anzeigen</Button></td></tr>
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>

                    )}
                </Accordion>
            </div>
            <div className="hidden md:flex md:flex-col md:items-center w-full">
                <Table striped>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Rechnungsnummer</th>
                            <th>Kunde</th>
                            <th>Start Datum</th>
                            <th>End Datum</th>
                            <th>Fälligkeitsdatum</th>
                            <th className="text-right">Gesamtkosten</th>
                        </tr>
                    </thead>
                    <tbody>
                        {notSettled?.map(invoice =>
                            <tr key={invoice.id} className="cursor-pointer" onClick={() => navigation(`/invoices/${invoice.id}`)}>
                                <td><FontAwesomeIcon icon={faEnvelopeOpen} />{publishedIcon(invoice.isPublished)}</td>
                                <td>{invoice.invoiceNumber}</td>
                                <td>{invoice.customer.name}</td>
                                <td>{asLocalDate(invoice.startDate)}</td>
                                <td>{asLocalDate(invoice.endDate)}</td>
                                <td>{asLocalDate(invoice.dueDate)}</td>
                                <td className="text-right">{localStringFrom(invoice.totalCost)}€</td>
                            </tr>
                        )}
                        {showSettled && settled?.map(invoice =>
                            <tr key={invoice.id} className="cursor-pointer" onClick={() => navigation(`/invoices/${invoice.id}`)}>
                                <td><FontAwesomeIcon icon={faCircleCheck} />{publishedIcon(invoice.isPublished)}</td>
                                <td>{invoice.invoiceNumber}</td>
                                <td>{invoice.customer.name}</td>
                                <td>{asLocalDate(invoice.startDate)}</td>
                                <td>{asLocalDate(invoice.endDate)}</td>
                                <td>{asLocalDate(invoice.dueDate)}</td>
                                <td className="text-right">{localStringFrom(invoice.totalCost)}€</td>
                            </tr>
                        )}
                        {(invoices?.length ?? 0) === 0 && !isError && !isLoading &&
                            <tr>
                                <td colSpan={9}>Noch keine Rechnungen angelegt...</td>
                            </tr>
                        }
                        {isError &&
                            <tr>
                                <td colSpan={9}>Es gab einen fehler beim Laden der Rechnungen...</td>
                            </tr>
                        }
                        {isLoading &&
                            <tr>
                                <td colSpan={9}><Spinner /></td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </div>
        </>
    )
}