import { QueryClient, useQuery } from "@tanstack/react-query"
import { GetBackendClient } from "../../services/BackendClientFactory"
import { useAuthStateQuery } from "../token/TokenQuery"
import { UseMock } from "../../util/Envs"

export const invoiceQueryKey = "invoices"

export const useInvoiceQuery = () => {
    const { msToken, allReady } = useAuthStateQuery()

    const { data, isLoading, isError } = useQuery({
        queryKey: [invoiceQueryKey],
        queryFn: getInvoices(msToken),
        enabled: allReady
    })

    const invoicesById = data !== undefined ? new Map(data.map(i => [i.id, i])) : undefined

    return { invoices: data, invoicesById, isLoading, isError }
}

export const useInvoiceByIdQuery = (invoiceId: string|undefined) => {
    const { invoices, invoicesById, isLoading, isError } = useInvoiceQuery()

    if(UseMock){
        return { invoice: invoices ? invoices[0] : undefined, isLoading, isError  }
    }

    return { invoice: invoiceId ? invoicesById?.get(invoiceId) : undefined, isLoading, isError  }
}

export const prefetchInvoices = async (msToken: string, queryClient: QueryClient) => {
    await queryClient.prefetchQuery({
        queryKey: [invoiceQueryKey],
        queryFn: getInvoices(msToken),
    })
}

const getInvoices = (msToken: string|undefined) => async () => {
    const resp = await GetBackendClient(msToken).invoices.getInvoices()
    return resp.data
}