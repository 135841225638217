import { useMutation, useQueryClient } from "@tanstack/react-query"
import { GetBackendClient } from "../../services/BackendClientFactory"
import { useAuthStateQuery } from "../token/TokenQuery"
import { CreateOneTimeCommodityResource, OneTimeCommodityResource } from "../../generated/BackendApiClient"
import { oneTimeCommodityQueryKey } from "./OneTimeCommodityQueries"

export const useCreateOneTimeCommodity = () => {
    const { msToken } = useAuthStateQuery()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (args: CreateOneTimeCommodityResource) => {
            const resp = await GetBackendClient(msToken).oneTimeCommodities.createOneTimeCommodity(args)
            return resp.data
        },
        onSuccess: (newCommodity: OneTimeCommodityResource) => {
            queryClient.setQueryData([oneTimeCommodityQueryKey], (oldCommodities: OneTimeCommodityResource[] | undefined) => [...oldCommodities ?? [], newCommodity])
        }
    })
}

export const useDeleteOneTimeCommodity = () => {
    const { msToken } = useAuthStateQuery()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (toBeDeletedId: string) => {
            const resp = await GetBackendClient(msToken).oneTimeCommodities.deleteOneTimeCommodity(toBeDeletedId)
            return resp.data
        },
        onSuccess: (deletedCommodity: OneTimeCommodityResource) => {
            queryClient.setQueryData([oneTimeCommodityQueryKey], (oldCommodities: OneTimeCommodityResource[] | undefined) => oldCommodities?.filter(c => c.id !== deletedCommodity.id))
        }
    })
}