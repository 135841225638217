export function localStringFrom(number: number, decimalPlaces = 2) {
    const negative = number < 0
    number = Math.abs(number)

    const factor = Math.pow(10, decimalPlaces);
    const eps = 1 / Math.pow(10, decimalPlaces + 1);
    let rounded = (Math.round(number * factor) / factor) + eps;
    let str = rounded.toLocaleString();
    const res = str.substring(0, str.length - 1);

    if (negative) {
        return "-" + res
    } else {
        return res
    }
}