import { FC, useState } from "react"
import { InvoiceList } from "../components/InvoiceList"
import { Form } from "react-bootstrap"

export const InvoicePage: FC = () => {

    const [showSettled, setShowSettled] = useState(false)

    return (
        <>
            <h3>Rechnungen</h3>
            <Form.Check
                type="switch"
                label="Abgerechnete anzeigen:"
                checked={showSettled}
                onChange={e => setShowSettled(e.target.checked)}
            />
            <InvoiceList showSettled={showSettled}/>
        </>
    )
}