import { useQuery } from "@tanstack/react-query"
import { GetBackendClient } from "../../services/BackendClientFactory"
import { useAuthStateQuery } from "../token/TokenQuery"

export const billableOneTimeCommodityQueryKey = "billable-oneTimeCommodities"
export const billableRepeatingCommodityQueryKey = "billable-repeatingCommodities"

export const useBillableOneTimeCommoditiesQuery = (customerId: string, startDate: string, endDate: string) => {
    const { msToken, allReady } = useAuthStateQuery()

    const { data, isLoading, isError } = useQuery({
        queryKey: [billableOneTimeCommodityQueryKey, customerId, startDate, endDate ],
        queryFn: async () => {
            const resp = await GetBackendClient(msToken).oneTimeCommodities.getBillableOneTimeCommodities(customerId, { startDate, endDate })
            return resp.data
        },
        enabled: allReady
    })

    return { billableOneTimeCommodities: data, isLoading, isError }
}

export const useBillableRepeatingCommoditiesQuery = (customerId: string, startDate: string, endDate: string) => {
    const { msToken, allReady } = useAuthStateQuery()

    const { data, isLoading, isError } = useQuery({
        queryKey: [billableRepeatingCommodityQueryKey, customerId, startDate, endDate],
        queryFn: async () => {
            const resp = await GetBackendClient(msToken).repeatingCommodities.getBillableRepeatingCommodities(customerId, { startDate, endDate })
            return resp.data
        },
        enabled: allReady
    })

    return { billableRepeatingCommodities: data, isLoading, isError }
}

export const useBillableCommoditiesQuery = (customerId: string, startDate: string, endDate: string) => {
    const oneTime = useBillableOneTimeCommoditiesQuery(customerId, startDate, endDate)
    const repeating = useBillableRepeatingCommoditiesQuery(customerId, startDate, endDate)

    return { 
        billableOneTimeCommodities:oneTime.billableOneTimeCommodities, 
        billableRepeatingCommodities: repeating.billableRepeatingCommodities,
        isLoading: oneTime.isLoading || repeating.isLoading,
        isError: oneTime.isError || repeating.isError
    }
}