import { useQuery } from "@tanstack/react-query"
import { GetBackendClient } from "../../services/BackendClientFactory"
import { useAuthStateQuery } from "../token/TokenQuery"

export const statsQueryKey = "stats"

export const useStatsQuery = () => {
    const { msToken, allReady } = useAuthStateQuery()

    const { data } = useQuery({
        queryKey: [statsQueryKey],
        queryFn: async () => {
            const resp = await GetBackendClient(msToken).stats.getBasicStats()
            return resp.data
        },
        enabled: allReady
    })

    return { basicStatistics: data }
}