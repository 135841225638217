import { FC, useState } from "react"
import { CustomerResource } from "../generated/BackendApiClient"
import { Form, Button } from "react-bootstrap"

type CustomerFormProps = {
    onSubmit: (customer:CustomerResource) => Promise<CustomerResource>,
    initialData?: CustomerResource,
    type: "create" | "edit"
}

const emptyCustomer : CustomerResource = {
    id: "",
    customerNumber: "",
    name: "",
    email: "",
    cityName: "",
    houseNumber: "",
    postalCode: "",
    streetName: "",
    contactName: undefined
} 

export const CustomerForm : FC<CustomerFormProps> = ({onSubmit, type, initialData}) => {
    
    const [data, setData] = useState<CustomerResource>(initialData ?? emptyCustomer)
    
    const onChange : React.ChangeEventHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name
        const value = event.target.value
        setData(oldData => ({...oldData, [name]: value}))
    }

    return (
        <Form style={{maxWidth: 500}} onSubmit={(e) => {e.preventDefault(); onSubmit(data).then(newData => setData(type === "create" ? emptyCustomer : newData))}}>
            <Form.Group className="mb-3">
                <Form.Label>KundenNr.</Form.Label>
                <Form.Control required type="text" name="customerNumber" placeholder="123" onChange={onChange} value={data.customerNumber} />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control required type="text" name="name" placeholder="The Shop GmbH" onChange={onChange} value={data.name} />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Ansprechpartner (optional)</Form.Label>
                <Form.Control type="text" name="contactName" placeholder="Max Mustermann" onChange={onChange} value={data.contactName ?? ""} />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>E-Mail</Form.Label>
                <Form.Control required type="email" name="email" placeholder="info@theshop.de" onChange={onChange} value={data.email} />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Adresse</Form.Label>
                <div className="flex flex-row" >
                    <Form.Control className="basis-3/4" required type="text" name="streetName" placeholder="Heimlichstraße" onChange={onChange} value={data.streetName} />
                    <Form.Control className="basis-1/4" required type="text" name="houseNumber" placeholder="42" onChange={onChange} value={data.houseNumber} />
                </div>
                <div className="flex flex-row">
                    <Form.Control className="basis-2/5" required type="text" name="postalCode" placeholder="77855" onChange={onChange} value={data.postalCode} />
                    <Form.Control className="basis-3/5" required type="text" name="cityName" placeholder="Achern" onChange={onChange} value={data.cityName} />
                </div>
            </Form.Group>

            <Button className="w-full" variant="primary" type="submit">
                {type === "create" && "Erstellen"}
                {type === "edit" && "Bearbeiten"}
            </Button>
        </Form>
    )
}