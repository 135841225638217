import { useMsal } from "@azure/msal-react";
import { FC } from "react";
import { scopes, useAuthStateQuery } from "../store/token/TokenQuery";
import { Button } from "react-bootstrap";
import { useLogoutMutator } from "../store/token/TokenMutators";
import { Link } from "react-router-dom";

export const LoginPage: FC = () => {
    const { allReady, msName } = useAuthStateQuery()
    const { logoutMutation } = useLogoutMutator()
    const { instance } = useMsal()
    
    if(allReady){
        return (
            <>
                <h3>Hallo <b>{msName}</b></h3>
                <Link to='/'>
                    <img src="/logo512.png" alt="logo" width={100} height={100}/>
                </Link>
                <Button className="w-full md:w-40" onClick={() => logoutMutation.mutate()}>Ausloggen</Button>
            </>
        )
    }

    return (
        <>
            <h3>Sentinel-Billing</h3>
            <img src="/logo512.png" alt="logo" width={100} height={100}/>
            <Button className="w-full md:w-40" onClick={() => instance.loginRedirect({ scopes: scopes })}>Einloggen</Button>
        </>
    )
}