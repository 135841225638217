import React, { FC } from 'react';
import { InvoiceFooter } from './InvoiceFooter';
import { InvoiceHeader } from './InvoiceHeader';
import { InvoiceItems } from './InvoiceItems';
import { InvoiceLegal } from './InvoiceLegal';
import { InvoiceResource } from '../../generated/BackendApiClient';

type FullInvoiceProps = {
    invoice: InvoiceResource
}

export const FullInvoice : FC<FullInvoiceProps> = ({invoice}) => {

    return (
        <div style={{height:'100%', display: 'grid'}}>
            <InvoiceHeader invoice={invoice}/>
            <InvoiceLegal invoice={invoice}/>
            <InvoiceItems invoice={invoice}/>
            <InvoiceFooter/>
        </div>
    );
}