import { QueryClient, useQuery, useQueryClient } from "@tanstack/react-query"
import { GetBackendClient } from "../../services/BackendClientFactory"
import { useAuthStateQuery } from "../token/TokenQuery"

export const oneTimeCommodityQueryKey = "oneTimeCommodities"

export const useOneTimeCommodityQuery = () => {
    const { msToken, allReady } = useAuthStateQuery()

    const { data, isError, isLoading } = useQuery({
        queryKey: [oneTimeCommodityQueryKey],
        queryFn: getOneTimeCommodities(msToken),
        enabled: allReady
    })

    return { oneTimeCommodities: data, isError, isLoading }
}

export const prefetchOneTimeCommodities = async (msToken: string, queryClient: QueryClient) => {
    await queryClient.prefetchQuery({
        queryKey: [oneTimeCommodityQueryKey],
        queryFn: getOneTimeCommodities(msToken),
    })
}

const getOneTimeCommodities = (msToken: string|undefined) => async () => {
    const resp = await GetBackendClient(msToken).oneTimeCommodities.getOneTimeCommodities()
    return resp.data
}