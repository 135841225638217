import { FC, useState } from "react"
import { CreateOneTimeCommodityResource } from "../generated/BackendApiClient"
import { Form } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { useCustomerQuery } from "../store/customers/CustomerQueries"
import { useCreateOneTimeCommodity } from "../store/oneTimeCommodities/OneTimeCommodityMutations"

const emptyCreationData: CreateOneTimeCommodityResource = {
    name: '',
    amount: 0,
    costOfOne: 0,
    date: '',
    description: '',
    receivingCustomerId: '',
    unit: 'h'
}

export const OneTimeCommoditiesCreationLine: FC = () => {

    const [data, setData] = useState<CreateOneTimeCommodityResource>(emptyCreationData)
    const { customers } = useCustomerQuery()
    const createOneTimeCommodity = useCreateOneTimeCommodity()

    const onChange: React.ChangeEventHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name
        const value = event.target.value
        setData(oldData => ({ ...oldData, [name]: value }))
    }

    const onCreateClicked = async () => {
        await createOneTimeCommodity.mutateAsync({...data, receivingCustomerId: data.receivingCustomerId === '' ? customers![0].id : data.receivingCustomerId })
        setData(emptyCreationData)
    }

    return (
        <tr key='creation'>
            <td></td>
            <td><Form.Control required type="text" name="name" placeholder="Erstellen einer Webseite" onChange={onChange} value={data.name} /></td>
            <td>
                <Form.Select aria-label="Default select example" name="receivingCustomerId" onChange={onChange} value={data.receivingCustomerId} >
                    {customers?.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
                </Form.Select>
            </td>
            <td><Form.Control required type="number" name="amount" placeholder="42" onChange={onChange} value={data.amount} /></td>
            <td>
                <Form.Select aria-label="Default select example" name="unit" onChange={onChange} value={data.unit} >
                    <option value='h'>h</option>
                    <option value='Stk'>Stk</option>
                </Form.Select>
            </td>
            <td><Form.Control required type="number" name="costOfOne" placeholder="42" onChange={onChange} value={data.costOfOne} /></td>
            <td><Form.Control required type="date" name="date" placeholder="42" onChange={onChange} value={data.date} /></td>
            <td><Form.Control required type="text" name="description" placeholder="Erstellen der Webseite domain.de" onChange={onChange} value={data.description} /></td>
            <td><FontAwesomeIcon className="cursor-pointer" icon={faPlus} onClick={onCreateClicked} /></td>
        </tr>
    )
}