import { useMutation, useQueryClient } from "@tanstack/react-query"
import { GetBackendClient } from "../../services/BackendClientFactory"
import { useAuthStateQuery } from "../token/TokenQuery"
import { BuildInvoiceActionResource, InvoiceResource } from "../../generated/BackendApiClient"
import { invoiceQueryKey } from "./InvoiceQueries"
import { oneTimeCommodityQueryKey } from "../oneTimeCommodities/OneTimeCommodityQueries"
import { repeatingCommodityQueryKey } from "../repeatingCommodities/RepeatingCommodityQueries"
import { billableOneTimeCommodityQueryKey, billableRepeatingCommodityQueryKey } from "../billables/BillableQueries"


export const useCreateInvoiceMutation = () => {
    const { msToken } = useAuthStateQuery()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (args: BuildInvoiceActionResource) => {
            const response = await GetBackendClient(msToken).invoice.createInvoice(args)
            return response.data
        },
        onSuccess: (newInvoice: InvoiceResource) => {
            queryClient.invalidateQueries({queryKey: [oneTimeCommodityQueryKey]})
            queryClient.invalidateQueries({queryKey: [repeatingCommodityQueryKey]})
            queryClient.invalidateQueries({queryKey: [billableOneTimeCommodityQueryKey]})
            queryClient.invalidateQueries({queryKey: [billableRepeatingCommodityQueryKey]})
            queryClient.setQueryData([invoiceQueryKey], (oldInvoices: InvoiceResource[] | undefined) => [...oldInvoices ?? [], newInvoice])
        }
    })
}

export const useSettleUpInvoiceMutation = () => {
    const { msToken } = useAuthStateQuery()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (invoiceId: string) => {
            await GetBackendClient(msToken).invoices.settleUp(invoiceId)
            return invoiceId
        },
        onSuccess: (invoiceId: string) => {
            queryClient.setQueryData([invoiceQueryKey], (oldInvoices: InvoiceResource[]) => {
                const invoice = oldInvoices.filter(i => i.id === invoiceId)[0]
                return [...oldInvoices.filter(i => i.id !== invoiceId), { ...invoice, isSettled: true }]
            })
        }
    })
}

export const usePublishInvoiceMutation = () => {
    const { msToken } = useAuthStateQuery()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (invoiceId : string) => {
            const response = await GetBackendClient(msToken).invoices.publishInvoice(invoiceId)
            return response.data
        },
        onSuccess: (updatedInvoice: InvoiceResource) => {
            queryClient.setQueryData([invoiceQueryKey], (oldInvoices: InvoiceResource[] | undefined) => [...(oldInvoices ?? []).filter(i => i.id !== updatedInvoice.id), updatedInvoice])
        }
    })
}

export const useDeleteInvoiceMutation = () => {
    const { msToken } = useAuthStateQuery()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (invoiceId : string) => {
            await GetBackendClient(msToken).invoices.deleteInvoice(invoiceId)
            return invoiceId
        },
        onSuccess: (invoiceId: string) => {
            queryClient.invalidateQueries({queryKey: [invoiceQueryKey]})
            queryClient.invalidateQueries({queryKey: [oneTimeCommodityQueryKey]})
            queryClient.invalidateQueries({queryKey: [repeatingCommodityQueryKey]})
            queryClient.invalidateQueries({queryKey: [billableOneTimeCommodityQueryKey]})
            queryClient.invalidateQueries({queryKey: [billableRepeatingCommodityQueryKey]})
            queryClient.setQueryData([invoiceQueryKey], (oldInvoices: InvoiceResource[] | undefined) => (oldInvoices ?? []).filter(i => i.id !== invoiceId))
        }
    })
}