import { FC, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { TimeSpan, TimeSpanSelection } from "../components/TimeSpanSelection";
import { BillableItemSelection } from "../components/BillableItemSelection";

export const BillingPage: FC = () => {
    const { customerId } = useParams();

    const [timeSpan, setTimeSpan] = useState<TimeSpan|undefined>(undefined)

    if (customerId === undefined) return <Navigate to='/' />

    return (
        <>
            <TimeSpanSelection onSubmit={setTimeSpan}/>
            {timeSpan && <BillableItemSelection timeSpan={timeSpan} customerId={customerId}/>}
        </>
    )
}