import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import { QueryClientProvider } from '@tanstack/react-query';
import { MsalProvider } from '@azure/msal-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { SetupConfiguration, setUp } from './Setup';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

setUp().then(config => render(config))

const render = (config: SetupConfiguration) => {
  root.render(
    <React.StrictMode>
      <QueryClientProvider client={config.queryClient}>
        <MsalProvider instance={config.msalInstance}>
          <App />
        </MsalProvider>
      </QueryClientProvider>
    </React.StrictMode>
  )
}