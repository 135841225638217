import { FC, useState } from "react";
import { OneTimeCommoditiesList } from "../components/OneTimeCommoditiesList";
import { Form } from "react-bootstrap";
import { RepeatingCommoditiesList } from "../components/RepeatingCommoditiesList";

export const CommoditiesPage: FC = () => {
    
    const [showSettled, setShowSettled] = useState(false)
    
    return (
        <>
            <h3 className="text-lg md:text-3xl">Einmalige Dienstleistungen</h3>
            <Form.Check
                type="switch"
                label="Abgerechnete anzeigen:"
                checked={showSettled}
                onChange={e => setShowSettled(e.target.checked)}
            />
            <OneTimeCommoditiesList showSettled={showSettled}/>
            <h3 className="text-lg md:text-3xl">Wiederkehrende Dienstleistungen</h3>
            <RepeatingCommoditiesList/>
        </>
    )
}