import { FC } from "react";
import { Accordion, Spinner, Table } from "react-bootstrap";
import { useCustomerQuery } from "../store/customers/CustomerQueries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useDeleteCustomerMutation } from "../store/customers/CustomerMutations";
import { CustomerResource } from "../generated/BackendApiClient";
import { Link } from "react-router-dom";


export const CustomerList: FC = () => {

    const { customers, isError, isLoading } = useCustomerQuery()

    const deleteCustomer = useDeleteCustomerMutation()

    const onDeleteClicked = (customer: CustomerResource) => async () => {
        if (!window.confirm(`Willst du wirklich '${customer.name}' löschen?`)) return

        await deleteCustomer.mutateAsync(customer.id)
    }

    return (
        <>
            <div className="md:hidden block w-full">
                <Accordion className="w-full">
                    {customers?.map(c =>

                        <Accordion.Item key={c.id} eventKey={c.id}>
                            <Accordion.Header>{c.name}</Accordion.Header>
                            <Accordion.Body>
                                <Table>
                                    <tbody>
                                        <tr><td>Kundennummer:</td><td>{c.customerNumber}</td></tr>
                                        <tr><td>Ansprechpartner:</td><td>{c.contactName ?? '-'}</td></tr>
                                        <tr><td>E-Mail:</td><td>{c.email}</td></tr>
                                        <tr><td>Straße:</td><td>{c.streetName} {c.houseNumber}</td></tr>
                                        <tr><td>Ort:</td><td>{c.postalCode} {c.cityName}</td></tr>
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>

                    )}
                </Accordion>
            </div>
            <div className="hidden md:flex md:flex-col md:items-center w-full">
                <Table striped>
                    <thead>
                        <tr>
                            <th></th>
                            <th>KundenNr.</th>
                            <th>Name</th>
                            <th>Ansprechpartner</th>
                            <th>E-Mail</th>
                            <th>Adresse</th>
                        </tr>
                    </thead>
                    <tbody>
                        {customers?.map(c =>
                            <tr key={c.id}>
                                <td><Link to={`/bill/${c.id}`} className="text-black"><FontAwesomeIcon className="cursor-pointer" icon={faMoneyBill} /> Abrechnen</Link></td>
                                <td>{c.customerNumber}</td>
                                <td>{c.name}</td>
                                <td>{c.contactName ?? '-'}</td>
                                <td>{c.email}</td>
                                <td>{c.streetName} {c.houseNumber} - {c.postalCode} {c.cityName}</td>
                                <td><FontAwesomeIcon className="cursor-pointer text-red-400" icon={faTrash} onClick={onDeleteClicked(c)} /></td>
                            </tr>
                        )}
                        {(customers?.length ?? 0) === 0 && !isError && !isLoading &&
                            <tr>
                                <td colSpan={5}>Noch keine Kunden angelegt...</td>
                            </tr>
                        }
                        {isError &&
                            <tr>
                                <td colSpan={5}>Es gab einen fehler beim Laden der Kunden...</td>
                            </tr>
                        }
                        {isLoading &&
                            <tr>
                                <td colSpan={5}><Spinner /></td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </div>
        </>
    )
} 